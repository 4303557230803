import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Loading from "../Components/Loading";
import CryptoJS from "crypto-js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "../Helper/config";

function Login() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const signInUser = (e) => {
    if (!userData?.email || !userData.password) {
      toast.warn("Error! Please fill all the fields.");
    } else {
      setLoading(true);
      const encrypted = CryptoJS.AES.encrypt(
        userData?.password,
        process.env.REACT_APP_JWT_SECRET
      ).toString();
      let data = JSON.stringify({ ...userData, password: encrypted });

      let config = {
        method: "post",
        url: `${baseUrl}/v1/signIn`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setLoading(false);
          if (response?.data?.success) {
            localStorage.setItem(
              "__OrientyBlackCarService__",
              JSON.stringify(response?.data)
            );
            navigate("/dashboard");
            window.location.reload();
          } else {
            toast.warn("Email & Password mismatch.");
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.warn("Error! Please try again later.");
        });
    }
  };
  const forgotPass = (e) => {
    if (!userData?.email) {
      toast.warn("Error! Please enter the registred email.");
    } else {
      setLoading(true);
      const encrypted = CryptoJS.AES.encrypt(
        userData?.password,
        process.env.REACT_APP_JWT_SECRET
      ).toString();
      let data = JSON.stringify({ ...userData, password: encrypted });

      let config = {
        method: "post",
        url: `${baseUrl}/v1/forgotPass`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setLoading(false);
          if (response?.data?.success) {
            toast.success(response?.data?.message);
          } else {
            toast.warn(response?.data?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.warn("Error! Please try again later.");
        });
    }
  };

  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <section id="section-hero" aria-label="section" className="jarallax">
          <img src="images/background/2.jpg" className="jarallax-img" alt="" />
          <div className="v-center">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-lg-4 offset-lg-4"
                  style={{
                    zIndex: 9,
                  }}
                >
                  <div
                    className="padding40 rounded-3 shadow-soft bgcstom"
                    data-bgcolor="#ffffff"
                  >
                    <h4>Login</h4>
                    <div className="spacer-10"></div>
                    <div id="form_register" className="form-border">
                      <div className="field-set">
                        <input
                          type="text"
                          name="email"
                          value={userData.email}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Email"
                        />
                      </div>
                      <div className="field-set">
                        <input
                          type="password"
                          name="password"
                          value={userData.password}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Password"
                        />
                      </div>
                      <p
                        className="text-center"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={forgotPass}
                      >
                        Forgot Password?
                      </p>
                      <div id="submit">
                        <button
                          id="send_message"
                          className="btn-main btn-fullwidth rounded-3"
                          onClick={signInUser}
                        >
                          Sign In
                        </button>
                      </div>
                      <a
                        className="text-center"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          width: "100%",
                          textAlign: "center",
                          display: "block",
                          marginTop: 10,
                        }}
                        href="/register"
                      >
                        Register
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Login;
